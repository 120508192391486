import React from 'react';
import styles from './ContactUs.module.css';
import logo from '../../assets/img/nostalgia.webp';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedinIn, } from 'react-icons/fa';

export default function ContactUs() {
    return (
        <div className={styles.contactUsSection}>
            <div className={styles.leftSide}>
                <img src={logo} alt="Nostalgia Logo" className={styles.logo} />
                <div className={styles.socialIcons}>
                    <a href="https://www.facebook.com" target="_blank" rel="noreferrer"><FaFacebook /></a>
                    <a href="https://www.twitter.com" target="_blank" rel="noreferrer"><FaTwitter /></a>
                    <a href="https://www.instagram.com" target="_blank" rel="noreferrer"><FaInstagram /></a>
                    <a href="https://www.linkedin.com" target="_blank" rel="noreferrer"><FaLinkedinIn /></a>
                </div>
                <div className={styles.contactInfo}>
                    <p>123 Street, Copenhagen, Denmark</p>
                    <p className={styles.contactText}>You can reach us on +45 1234 5678</p>
                    <div className={styles.openSchedule}>
                        <h3>Opening Hours</h3>
                        <div className={styles.scheduleItem}><span>Monday - Friday</span> <span>9am - 6pm</span></div>
                        <div className={styles.scheduleItem}><span>Saturday</span> <span>10am - 4pm</span></div>
                        <div className={styles.scheduleItem}><span>Sunday</span> <span>Closed</span></div>
                    </div>

                </div>
            </div>
            <div className={styles.rightSide}>
                <iframe
                    title="Google Map"
                    className={styles.googleMap}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2248.654823120461!2d12.568337315929092!3d55.676096980529176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4652533c5c803d23%3A0x4dd7edde4df05a29!2sCopenhagen%2C%20Denmark!5e0!3m2!1sen!2s!4v1632434096381!5m2!1sen!2s"
                    allowFullScreen=""
                    loading="lazy"
                />
            </div>
        </div>
    );
}
