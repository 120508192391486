import React, {useEffect, useState} from 'react';
import styles from './Preloader.module.css';

const Preloader = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    clearInterval(interval);
                    return 100;
                }
                return oldProgress + 1;
            });
        }, 10);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className={styles['preloader-wrap']}>
            <div className={styles.outer}>
                <div className={styles.inner}>
                    <div className={styles.trackbar}>
                        <div className={styles['preloader-intro']}>
                            <span>Loading {progress}%</span>
                        </div>
                        <div className={styles.loadbar} style={{width: `${progress}%`}}></div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default Preloader;
