import React from 'react';
import styles from './Blog.module.css';
import placeholderImage from '../../assets/img/blog-image.webp';

function Blog() {
    const articles = Array(6).fill({
        title: 'Sample Article Title',
        summary: 'This is a summary of the sample article. It provides a brief overview of the content.',
    });

    return (
        <div className={styles.blogContainer}>
            <div className={styles.contentWrapper}>
                <h1 className={styles.pageTitle}>We keep you in the loop!</h1>
                <p className={styles.pageSubtitle}>Explore our latest insights and stories.</p>
                <div className={styles.articlesGrid}>
                    {articles.map((article, index) => (
                        <div key={index} className={styles.articleCard}>
                            <img src={placeholderImage} alt="Article" className={styles.articleImage} />
                            <div className={styles.textContainer}>
                                <h2 className={styles.articleTitle}>{article.title}</h2>
                                <p className={styles.articleSummary}>{article.summary}</p>
                                <a href="#read-more" className={styles.readMoreLink}>Read More</a>
                            </div>
                        </div>

                    ))}
                </div>
            </div>
        </div>
    );
}

export default Blog;