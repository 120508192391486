// Library Imports
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// Component Imports
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Products from './components/Products/Products';
import FeaturedProducts from './components/FeaturedProducts/FeaturedProducts';
import About from './components/About/About';
import Footer from './components/Footer/Footer';
import Preloader from './components/Preloader/Preloader';
import Testimonials from "./components/Testimonials/Testimonials";
import ContactUs from "./components/ContactUs/ContactUs";
import Contact from "./components/Contact/Contact"
import NotFound from './components/NotFound/NotFound';
import Login from './components/Login/Login';
import Cart from './components/Cart/Cart';
import Blog from "./components/Blog/Blog";

function App() {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');

        if (!hasVisitedBefore) {
            setIsLoading(true);

            // Hide the preloader after 2 seconds
            setTimeout(() => {
                setIsLoading(false);
            }, 2000);
        }
    }, []);

    return (
        <Router>
            {isLoading ? (
                <Preloader />
            ) : (
                <>
                    <Header />
                    <Routes>
                        <Route path="/" element={
                            <>
                                <Hero />
                                <FeaturedProducts />
                                <About />
                                <Testimonials />
                                <Contact />
                            </>} />
                        <Route path="/products" element={<Products />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/cart" element={<Cart />} />
                        <Route path="/blog" element={<Blog />} />
                        <Route path="/contact-us" element={<ContactUs />} />
                        <Route path="/404" element={<NotFound />} />
                        <Route path="*" element={<Navigate to="/404" replace />} />
                    </Routes>
                    <Footer />
                </>
            )}
        </Router>
    );
}

export default App;
