import React from 'react';
import styles from './About.module.css';
import stockImage from '../../assets/img/stock-image-1.webp';

export default function About() {
    return (
        <div className={styles.aboutSection}>
            <div className={styles.glowLine}></div>

                <div className={styles.placeholder}></div>

            <div className={styles.content}>
                <div className={styles.textBox}>
                    <h2>Driven by Innovation</h2>
                    <p>At our company, we believe in pushing the boundaries of technology to provide our customers with the best possible products and services. Our commitment to innovation and excellence is what sets us apart in the industry.</p>
                </div>
                <div className={styles.imageBox}>
                    <img src={stockImage} alt="Technology" />
                </div>
            </div>
        </div>
    );
}
