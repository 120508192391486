import React, { useEffect } from 'react';
import styles from './Cart.module.css';
import { gsap } from 'gsap';
import { Link } from 'react-router-dom';
import emptyCartImage from '../../assets/img/shopping-cart-icon.png';

function Cart() {
    useEffect(() => {
        gsap.to(`.${styles.emptyCartImage}`, { scale: 1.05, duration: 1, repeat: -1, yoyo: true });
    }, []);

    return (
        <div className={styles.cartContainer}>
            <div className={styles.cartBox}>
                <img src={emptyCartImage} alt="Empty Cart" className={styles.emptyCartImage} />
                <h1 className={styles.title}>Your Shopping Cart is Empty</h1>
                <p className={styles.suggestion}>Discover amazing products and add them to your cart.</p>
                <Link to="/products">
                    <button className={styles.continueShoppingButton}>Continue Shopping</button>
                </Link>
            </div>
        </div>
    );
}

export default Cart;
