import React, { useState } from 'react';
import logo from '../../assets/img/nostalgia.webp';
import styles from './Login.module.css';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle login logic here
    };

    return (
        <div className={styles.loginContainer}>
            <div className={styles.loginBox}>
                <img src={logo} alt="Nostalgia Logo" className={styles.logo} />
                <h1 className={styles.title}>My Account</h1>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className={styles.inputField}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={styles.inputField}
                    />
                    <div className={styles.buttonContainer}>
                        <div>
                            <button type="submit" className={styles.loginButton}>Login</button>
                            <button className={styles.signUpButton}>Sign Up</button>
                        </div>
                        <p className={styles.forgotPassword}>Forgot password?</p>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;
