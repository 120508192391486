import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';
import { FaMapMarkerAlt, FaPhone, FaEnvelopeOpen, FaFacebookSquare, FaTwitterSquare, FaLinkedin } from 'react-icons/fa';

export default function Footer() {
    return (
        <div className={styles.footerWrapper}>
        <footer className={styles.footer}>
            <div className={styles.contactSection}>
                <div className={styles.contactItem}>
                    <a href="https://www.google.com/maps?q=+Copenhagen" target="_blank" rel="noopener noreferrer">
                        <FaMapMarkerAlt className={styles.icon} />
                        <div className={styles.contactText}>
                            <h4>Find Us</h4>
                            <span>Street 35, Copenhagen</span>
                        </div>
                    </a>
                </div>
                <div className={styles.contactItem}>
                    <a href="tel:+4535155151">
                        <FaPhone className={styles.icon} />
                        <div className={styles.contactText}>
                            <h4>Call Us</h4>
                            <span>+45 35155151</span>
                        </div>
                    </a>
                </div>
                <div className={styles.contactItem}>
                    <a href="mailto:random@mail.com">
                        <FaEnvelopeOpen className={styles.icon} />
                        <div className={styles.contactText}>
                            <h4>Mail Us</h4>
                            <span>random@mail.com</span>
                        </div>
                    </a>
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.leftSection}>
                    <h3 className={styles.companyName}>Important Tech Company</h3>
                    <p className={styles.slogan}>Innovating the Future</p>
                </div>
                <div className={styles.middleSection}>
                    <div className={styles.socialIcons}>
                        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                            <FaFacebookSquare />
                        </a>
                        <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                            <FaTwitterSquare />
                        </a>
                        <a href="https://www.linkedin.com/in/mathias-weibeck-60855618b/" target="_blank" rel="noopener noreferrer">
                            <FaLinkedin />
                        </a>
                    </div>
                </div>
                <div className={styles.rightSection}>
                    <ul>
                        <li><Link to="/contact-us">About Us</Link></li>
                        <li><Link to="/blog">Account</Link></li>
                        <li><Link to="/products">Check Out</Link></li>
                    </ul>
                    <ul>
                        <li><Link to="/contact-us">Opening Hours</Link></li>
                        <li><Link to="/blog">Blog Posts</Link></li>
                        <li><Link to="/products">Our Shop</Link></li>
                    </ul>
                </div>
            </div>
            <div className={styles.copyright}>
                <p>Copyright &copy; {new Date().getFullYear()}, All Right Reserved by Mathias Weibeck</p>
            </div>
        </footer>
            </div>
    );
}
