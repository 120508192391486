import React, { useState } from 'react';
import styles from './Contact.module.css';
import qrCodeImage from '../../assets/img/qr-code.webp';

export default function Contact() {


    const [newsletterConfirmation, setNewsletterConfirmation] = useState(false);
    const [contactConfirmation, setContactConfirmation] = useState(false);

    const handleNewsletterSubmit = (e) => {
        e.preventDefault();
        setNewsletterConfirmation(true);
    };

    const handleContactSubmit = (e) => {
        e.preventDefault();
        setContactConfirmation(true);
    };

    return (
        <div className={styles.contactSection}>
            <div className={styles.splitScreen}>
                <div className={styles.newsletter}>
                    <img src={qrCodeImage} alt="QR Code" className={styles.qrCode} />
                    <h2>Get the Latest Drops</h2>
                    <p>Enter your email to stay updated on our latest products and offers.</p>
                    <form onSubmit={handleNewsletterSubmit}>
                        <div className={styles.inputWrapper}>
                            <div className={styles.emailWrapper}>
                                <input type="email" id="email" placeholder="Enter Your Email" />
                            </div>
                            <button type="submit">Subscribe</button>
                        </div>
                        {newsletterConfirmation && <p className={styles.confirmationMessage}>Subscription successful!</p>}
                    </form>
                </div>


                <div className={styles.contactForm}>
                    <h2>Got Questions?</h2>
                    <p>We're here to help. Send us a message below:</p>
                    <form onSubmit={handleContactSubmit}>
                        <input type="email" placeholder="Your email address" />
                        <textarea placeholder="Hi, Important Tech Company"></textarea>
                        <button type="submit">Send Message</button>
                        {contactConfirmation && <p className={styles.confirmationMessage}>Message sent successfully!</p>}
                    </form>
                </div>
            </div>
        </div>
    );
}
