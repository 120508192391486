import styles from './Testimonials.module.css';
import React from "react";

export default function Testimonials() {

    const testimonials = [
        {
            quote: "This is the best product I've ever used! Delivery speed is fast! Customer Server is amazing! Every aspect of this is carefully measured to fit",
            author: "Happy Customer 1",
            rating: "5"
        },
        {
            quote: "I can't believe how much this product has improved my life. absolute innovation at its finest, customer service through the roof",
            author: "Happy Customer 2",
            rating: "4"
        },
        {
            quote: "They are top of the line.. woow! incredible product, amazed beyond the stars. Must be bug that i can only give 5 stars, i want to give 10",
            author: "Happy Customer 3",
            rating: "5"
        },
    ];

    return (
        <div className={styles.testimonials}>
            <h1 className={styles.title}>Hear It From Our Customers</h1>
            <div className={styles.container}>
                <div className={styles.testimonialGrid}>
                    {testimonials.slice(0, 3).map((testimonial, index) => (
                        <div key={index} className={styles.testimonialCard}>
                            <div className={styles.quoteBox}>
                                <p className={styles.quote}>"{testimonial.quote}"</p>
                            </div>
                            <div>
                                <p className={styles.stars}>{"⭐".repeat(testimonial.rating)}</p>
                                <p className={styles.author}>- {testimonial.author}</p>
                            </div>
                        </div>

                    ))}
                </div>
                <div className={styles.ratingSection}>
                    <button className={styles.seeAllButton}>See all testimonials</button>
                </div>
            </div>
        </div>
    );
}
