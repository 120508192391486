import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { gsap } from 'gsap';
import styles from './Header.module.css';
import logo from '../../assets/img/nostalgia.webp';
import { FaShoppingCart, FaUser } from 'react-icons/fa';

export default function Header() {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const headerRef = useRef();
    const logoRef = useRef();
    const navRef = useRef();
    const shopControlsRef = useRef(); // New ref for shop controls

    useEffect(() => {
        // Check if the user has visited the site before
        const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');

        if (!hasVisitedBefore) {
            gsap.fromTo(logoRef.current, { scale: 0, opacity: 0 }, { duration: 1, scale: 1, opacity: 1, delay: 2 });
            gsap.fromTo(navRef.current, { y: '-100px', opacity: 0 }, { duration: 1, y: '0px', opacity: 1, delay: 2.5 })
                .then(() => {
                    gsap.fromTo(shopControlsRef.current, { y: '-100px', opacity: 0 }, { duration: 1, y: '0px', opacity: 1, delay: 0.5 });
                    // Set a value in localStorage to indicate that the user has visited the site
                    localStorage.setItem('hasVisitedBefore', 'true');
                });
        } else {
            // If the user has visited before, immediately set final state up
            gsap.set(navRef.current, { y: '0px', opacity: 1 });
            gsap.set(shopControlsRef.current, { y: '0px', opacity: 1 });
            gsap.set(logoRef.current, { scale: 1, opacity: 1 });
        }
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 150) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className={`${styles.header} ${isScrolled ? styles.scrolled : ''}`} ref={headerRef}>
            <div className={styles.hamburger} onClick={handleMenuToggle}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className={`${styles.menuContainer} ${isMenuOpen ? styles.open : ''}`}>
                <Link to="/">
                    <div className={styles.logoWrapper}>
                        <img src={logo} className={styles.logo} alt="logo" ref={logoRef} />
                    </div>
                </Link>

                <nav className={styles.nav} ref={navRef}>
                <li><Link to="/" aria-label="Home" className={location.pathname === "/" ? styles.active : "" } onClick={handleMenuToggle}>Home</Link></li>
                <li><Link to="/products" aria-label="Shop" className={location.pathname === "/products" ? styles.active : ""} onClick={handleMenuToggle}>Shop</Link></li>
                <li><Link to="/about" aria-label="About" className={location.pathname === "/about" ? styles.active : ""} onClick={handleMenuToggle}>About</Link></li>
                <li><Link to="/blog" aria-label="Blog" className={location.pathname === "/blog" ? styles.active : ""} onClick={handleMenuToggle}>Blog</Link></li>
                <li><Link to="/contact-us" aria-label="Contact Us" className={location.pathname === "/contact-us" ? styles.active : ""} onClick={handleMenuToggle}>Contact</Link></li>
                </nav>

                <div className={styles.shopControls} ref={shopControlsRef}>
                <Link to="/cart" aria-label="Shopping Cart" onClick={handleMenuToggle}>
                    <FaShoppingCart className={styles.shopIcon} />
                    <span className={styles.shopText}>Cart</span>
                </Link>
                <Link to="/login" aria-label="My Account" onClick={handleMenuToggle}>
                    <FaUser className={styles.shopIcon} />
                    <span className={styles.shopText}>Account</span>
                </Link>
                </div>
            </div>
        </header>
    );
}
