// Hero.js
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { Link } from 'react-router-dom';
import styles from './Hero.module.css';
import heroImage from '../../assets/img/hero-image.webp';

export default function Hero() {
    const titleRef = useRef();
    const subtitleRef = useRef();
    const imageRef = useRef();
    const button1Ref = useRef();
    const button2Ref = useRef();

    useEffect(() => {
        gsap.to(imageRef.current, { duration: 1, opacity: 1, delay: 0.5 });
        gsap.to([titleRef.current, subtitleRef.current], { duration: 1, opacity: 1, delay: 1 });
        gsap.to([button1Ref.current, button2Ref.current], { duration: 1, opacity: 1, delay: 1.2 });
    }, []);

    return (
        <section className={styles.hero}>
            <div className={styles.heroText}>
                <h1 ref={titleRef} className={styles.heroTitle}>Important <span className={styles.keyword}>Tech</span> Company</h1>
                <p ref={subtitleRef} className={styles.heroSubtitle}>Super good <span className={styles.keyword}>subtext</span> or motto</p>
                <div className={styles.buttons}>
                    <Link ref={button1Ref} to="/products" className={styles.button}>Explore Products</Link>
                    <Link ref={button2Ref} to="/products" className={styles.button}>Browse Shop</Link>
                </div>
            </div>
            <div
                ref={imageRef}
                className={styles.heroImage}
                style={{backgroundImage: `url(${heroImage})`}}
            >
            </div>
        </section>
    );
}
