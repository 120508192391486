import React, { useState } from 'react';
import styles from './Products.module.css';
import { Range } from 'react-range';

export default function Products() {
    const [priceRange, setPriceRange] = useState([0, 500]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [sortOption, setSortOption] = useState('lowToHigh');

    const products = Array(12).fill({
        title: 'Product Name',
        description: 'Full description of the product...',
        price: 99.99,
        rating: 4.5,
        inStock: true,
    });

    const sortedProducts = products.sort((a, b) => {
        switch (sortOption) {
            case 'lowToHigh':
                return a.price - b.price;
            case 'highToLow':
                return b.price - a.price;
            case 'rating':
                return b.rating - a.rating;
            default:
                return 0;
        }
    });

    const handleReadMore = (product) => {
        setSelectedProduct(product);
        setIsModalOpen(true);
        document.body.style.overflow = 'hidden';
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        document.body.style.overflow = 'auto';
    };
    return (
        <div className={styles.productsContainer}>
            <div className={styles.filterSection}>
                <h3 className={styles.filterTitle}>Filter Products</h3>
                <div className={styles.filterOption}>
                    <label>Category:</label>
                    <select>
                        <option>Electronics</option>
                        <option>Software</option>
                        <option>Accessories</option>
                    </select>
                </div>
                <div className={styles.filterOption}>
                    <label>Brand:</label>
                    <select>
                        <option>Brand A</option>
                        <option>Brand B</option>
                        <option>Brand C</option>
                    </select>
                </div>
                <div className={styles.filterOption}>
                    <label>Rating:</label>
                    <select>
                        <option>5 Stars</option>
                        <option>4 Stars & Up</option>
                        <option>3 Stars & Up</option>
                    </select>
                </div>
                <div className={styles.filterOption}>
                    <div className={styles.sliderContainer}>
                        <Range
                            step={10}
                            min={0}
                            max={1000}
                            values={priceRange}
                            onChange={(values) => setPriceRange(values)}
                            renderTrack={({ props, children }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: '5px',
                                        width: '100%',
                                        backgroundColor: '#ddd',
                                    }}
                                >
                                    {children}
                                </div>
                            )}
                            renderThumb={({ props }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: '15px',
                                        width: '15px',
                                        backgroundColor: '#00d4ff',
                                        borderRadius: '50%',
                                        border: '2px solid #fff',
                                    }}
                                />
                            )}
                        />
                        <div className={styles.priceLabels}>
                            <span className={styles.minPriceLabel}>${priceRange[0]}</span>
                            <span className={styles.maxPriceLabel}>${priceRange[1]}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.productsSection}>
                <input type="text" placeholder="Search products..." className={styles.searchField} />
                <div className={styles.sortContainer}>
                    <label>Sort by:</label>
                    <select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
                        <option value="lowToHigh">Price: Low to High</option>
                        <option value="highToLow">Price: High to Low</option>
                        <option value="rating">Rating</option>
                    </select>
                </div>
                <div className={styles.productsGrid}>
                    {sortedProducts.map((product, index) => (
                        <div key={index} className={styles.productCard}>
                            <img src="/assets/img/product-filler.webp" alt="Product" className={styles.productImage} />
                            <h4 className={styles.productTitle}>{product.title}</h4>
                            <p className={styles.productPrice}>${product.price}</p>
                            <p className={styles.productRating}>Rating: {product.rating} Stars</p>
                            <p className={styles.productStock}>{product.inStock ? 'In Stock' : 'Out of Stock'}</p>
                            <div className={styles.buttonContainer}>
                            <button onClick={() => handleReadMore(product)} className={styles.readMoreButton}>Read More</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {isModalOpen && (
                <div className={styles.overlay} onClick={handleCloseModal}>
                    <div className={styles.modal} onClick={e => e.stopPropagation()}>
                    <button onClick={handleCloseModal} className={styles.closeButton}>Close</button>
                    <h3>{selectedProduct.title}</h3>
                    <img src="/assets/img/product-filler.webp" alt="Product" className={styles.productImage} />
                    <p>{selectedProduct.description}</p>
                    </div>
                </div>
            )}
        </div>
    );
}
