// FeaturedProducts.js
import React, { useState } from 'react';
import styles from './FeaturedProducts.module.css';
import { product1, product2, product3 } from '../../index';

export default function FeaturedProducts() {
    const [count, setCount] = useState(1);

    return (
        <section className={styles.featuredProducts}>
            <h2 className={styles.title}><span>Featured Products</span></h2>
            <div className={styles.grid}>
                {[product1, product2, product3].map((product, index) => (
                    <div className={styles.card} key={index}>
                        <img src={product} alt="Product" className={styles.image} />
                        <h3 className={styles.productTitle}>Product Name</h3>
                        <p className={styles.description}>This is a brief description of the product.</p>
                        <p className={styles.price}>$99.99</p>
                        <div className={styles.quantity}>
                            <button onClick={() => setCount(count > 1 ? count - 1 : 1)}>-</button>
                            <span>{count}</span>
                            <button onClick={() => setCount(count + 1)}>+</button>
                        </div>
                        <button className={styles.button}>Add to Basket</button>
                    </div>
                ))}
            </div>

            <div className={styles.cta}>
                <div className={styles.ctaContent}>
                    <h2>Explore More</h2>
                    <p>Discover our full range of products in the shop</p>
                    <a href="/products" className={styles.ctaButton}>Visit the shop</a>
                </div>
            </div>

        </section>
    );
}
